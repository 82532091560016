/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
import printerserver from '@/http/requests/printer/printerserver'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    let data = []
    let totalCount = 0
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    if (filters === null) {
      return {
        data: data,
        totalCount: 0,
      }
    }
    const response = await printerserver.getLabels(filters.printerVendorId)
    data = response.data.body
    totalCount = response.data.body.length
    return {
      data: data,
      totalCount: totalCount,
    }
  },
})

const labelsModelDataSource = new DataSource({
  store,
})

export {
  labelsModelDataSource as LabelsModelDataSource,
}
