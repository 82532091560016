export class EditLabel {
  constructor(
    name = '',
    format = '',
    width = 0,
    height = 0,
  ) {
    this.name = name
    this.format = format
    this.width = width
    this.height = height
  }
}
