export class AddLabelByVendor {
  constructor(
    name = '',
    format = '',
    width = 0,
    height = 0,
    vendorId = 0,
  ) {
    this.name = name
    this.format = format
    this.width = width
    this.height = height
    this.vendorId = vendorId
  }
}
