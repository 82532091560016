<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <!-- #region AddPopup -->
        <div id="app-container-add">
          <dx-util-popup
            :visible="addPopupVisible"
            :hide-on-outside-click="true"
            :show-title="true"
            :drag-enabled="true"
            :width="720"
            :height="445"
            position="center"
            title="Add Printer Label"
          >
            <dx-util-scroll-view>
              <div class="printermanagementmodal">
                <div class="printermanagement-container">
                  <dx-util-form
                    id="form"
                    :form-data="addfordata"
                    label-mode="static"
                    :read-only="false"
                    :show-colon-after-label="true"
                    label-location="left"
                    :min-col-width="300"
                    col-count="1"
                  >
                    <dx-util-simple-item
                      data-field="name"
                    />
                    <dx-util-simple-item
                      data-field="width"
                    />
                    <dx-util-simple-item
                      data-field="height"
                    />
                    <dx-util-simple-item
                      :col-span="2"
                      :editor-options="formatFieldOptions"
                      data-field="format"
                      editor-type="dxTextArea"
                    />
                  </dx-util-form>
                </div>
                <div class="printermanagement-footer">
                  <dx-util-button
                    class="savebutton"
                    text="Save"
                    @click="save()"
                  />
                  <dx-util-button
                    class="cancelbutton"
                    text="Close"
                    @click="closeAddPopup()"
                  />
                </div>
              </div>
            </dx-util-scroll-view>
          </dx-util-popup>
        </div>
        <!-- #endregion -->
        <!-- #region EditPopup -->
        <div id="app-container-edit">
          <dx-util-popup
            :visible="editPopupVisible"
            :hide-on-outside-click="true"
            :show-title="true"
            :drag-enabled="true"
            :width="720"
            :height="445"
            position="center"
            title="Edit Printer Label"
          >
            <dx-util-scroll-view>
              <div class="printermanagementmodal">
                <div class="printermanagement-container">
                  <dx-util-form
                    id="form"
                    :form-data="editfordata"
                    label-mode="static"
                    :read-only="false"
                    :show-colon-after-label="true"
                    label-location="left"
                    :min-col-width="300"
                    col-count="1"
                  >
                    <dx-util-simple-item
                      data-field="name"
                    />
                    <dx-util-simple-item
                      data-field="width"
                    />
                    <dx-util-simple-item
                      data-field="height"
                    />
                    <dx-util-simple-item
                      :col-span="2"
                      :editor-options="formatFieldOptions"
                      data-field="format"
                      editor-type="dxTextArea"
                    />
                  </dx-util-form>
                </div>
                <div class="printermanagement-footer">
                  <dx-util-button
                    class="savebutton"
                    text="Edit"
                    @click="editLocal()"
                  />
                  <dx-util-button
                    class="cancelbutton"
                    text="Close"
                    @click="closeEditPopup()"
                  />
                </div>
              </div>
            </dx-util-scroll-view>
          </dx-util-popup>
        </div>
        <!-- #endregion -->
        <!-- #region Preview -->
        <div id="app-container-preview">
        <dx-util-popup
          :visible="previewPopupVisible"
          :hide-on-outside-click="true"
          :show-title="true"
          :drag-enabled="true"
          :width="720"
          :height="'auto'"
          position="center"
          title="Label Format Review"
        >
          <dx-util-scroll-view class="printermanagement">
            <div class="printermanagementmodal">
              <div class="printermanagement-container">
                <div v-if="thereIsNoPrinter" class="printermanagement-errorbox">
                  There is no available printer to send test print out
                </div>
                <div class="mt-5px">
                  <dx-util-select-box
                    v-if="!thereIsNoPrinter"
                    :value="printers[0]"
                    :data-source="printers"
                    label-mode="static"
                    display-expr="Model"
                    label="Printer"
                    @value-changed="onChangePrinter"
                  />
                </div>
                <div class="mt-5px">
                  <dx-util-text-area
                    :height="90"
                    :read-only="true"
                    :value="valueOfReview"
                  />
                </div>
              </div>
              <div class="printermanagement-footer">
                <dx-util-button
                  :disabled="thereIsNoPrinter"
                  class="printoutbutton"
                  text="Send Test Print"
                  @click="sendTestPrint()"
                />
              </div>
            </div>
          </dx-util-scroll-view>
        </dx-util-popup>
      </div>
        <!-- #endregion -->
        <dx-data-grid
        ref="dataGrid"
        :data-source="LabelsModelDataSource"
        key-expr="id"
        :show-borders="true"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-sorting mode="single" />
        <dx-remote-operations
          :paging="true"
          :sorting="false"
          :filtering="false"
        />
        <template #filterToolbar>
          <div class="d-flex flex-row align-items-center devextreme-filter-panel">
            <div class="ml-5px">
              <dx-util-select-box
                :items="printerVendors"
                :value="selectedPrinterVendor"
                display-expr="name"
                @value-changed="onChangePrinterVendor"
              />
            </div>
          </div>
        </template>
        <!-- Id -->
        <dx-column
          data-field="id"
          :width="100"
        />
        <!-- Name -->
        <dx-column
          data-field="name"
        />
        <!-- Width -->
        <dx-column
          data-field="width"
        />
        <!-- Height -->
        <dx-column
          data-field="height"
        />
        <!-- Buttons -->
        <dx-column
          cell-template="selectionCellTemplate"
          :width="85"
        />
        <template #selectionCellTemplate="{ data }">
          <div>
            <button
              class="devextreme-detail-button"
              @click="showFormat(data)"
            >
              <feather-icon
                class="devextreme-cell-color-white"
                icon="PrinterIcon"
              />
            </button>
            <button
              class="devextreme-edit-button"
              @click="editAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-orange"
                icon="Edit2Icon"
              />
            </button>
            <button
              class="devextreme-remove-button"
              @click="deleteAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-red"
                icon="TrashIcon"
              />
            </button>
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { Notify } from '@robustshell/utils/index'
import printerserverapi from '@/http/requests/printer/printerserver'
import printeragentapi from '@/http/requests/printer/printer-agent'
import { AddLabelByVendor } from '@/http/models/printer/entity/Label/addLabelByVendor'
import { EditLabel } from '@/http/models/printer/entity/Label/editLabel'
import { PrintByRaw } from '@/http/models/printer/entity/printbyraw'
import { LabelsModelDataSource } from './labels'

export default {
  name: 'PrinterLabels',
  components: {
  },
  directives: {
  },
  mixins: [GridBase],
  props: ['vendorId'],
  data() {
    return {
      printerVendorId: 0,
      printerVendors: [],
      selectedPrinterVendor: null,
      LabelsModelDataSource,
      valueOfReview: '',
      previewPopupVisible: false,
      addPopupVisible: false,
      addLabel: new AddLabelByVendor(),
      addfordata: {
        name: '',
        height: 0,
        width: 0,
        format: '',
      },
      editPopupVisible: false,
      editLabel: new EditLabel(),
      editfordata: {
        name: '',
        height: 0,
        width: 0,
        format: '',
      },
      editid: 0,
      formatFieldOptions: { height: 140 },
      printers: [],
      selectedPrinter: null,
      thereIsNoPrinter: false,
      printByRaw: new PrintByRaw(),
    }
  },
  mounted() {
    this.printerVendorId = localStorage.getItem('printerVendorId')
    this.loadPrinterVendors()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.openAddPopup()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.refreshTable()
          },
        },
        location: 'after',
      })
    },
    openAddPopup() {
      if (this.addPopupVisible === true) { this.addPopupVisible = false }
      setTimeout(() => {
        this.addPopupVisible = true
      }, 100)
    },
    refreshTable() {
      this.$refs.dataGrid.instance.state(null)
    },
    onChangePrinterVendor(e2) {
      this.selectedPrinterVendor = e2.value
      this.loadLabels(e2.value)
    },
    // onChangePrinterModel(e2) {
    //   this.loadLabels(e2.value)
    // },
    onChangePrinter(e2) {
      this.selectedPrinter = e2.value
    },
    onChangeLabelType(e2) {
      this.loadLabels(e2.value)
    },
    loadPrinterVendors() {
      printerserverapi.getVendors().then(result => {
        this.printerVendors = []
        if (result.data.body === null) {
          Notify.error('Printer Vendors could not load')
        } else {
          const vendors = result.data.body
          const selectedVendorIndex = vendors.findIndex(item => Number(item.id) === Number(this.printerVendorId))
          this.selectedPrinterVendor = vendors[selectedVendorIndex]
          vendors.forEach(vendor => {
            this.printerVendors.push(vendor)
          })
        }
      }).then(() => {
        this.loadLabels(this.selectedPrinterVendor)
      })
    },
    loadLabels(vendor) {
      if (vendor !== null
      || vendor !== undefined) {
        LabelsModelDataSource.searchValue({ printerVendorId: vendor.id })
        LabelsModelDataSource.reload()
      }
    },
    showFormat(e) {
      this.loadPrinters()
      this.valueOfReview = e.data.format
      if (this.previewPopupVisible === true) { this.previewPopupVisible = false }
      setTimeout(() => {
        this.previewPopupVisible = true
      }, 100)
    },
    loadPrinters() {
      printeragentapi.availablePrinters().then(result => {
        this.printers.length = 0
        if (result && result.data && result.data.length > 0) {
          this.thereIsNoPrinter = false
          result.data.forEach(element => {
            this.printers.push(element)
          })
        }
      }).then(() => {
        if (this.printers.length === 0) {
          this.thereIsNoPrinter = true
        }
      }).catch(() => {
        if (this.printers.length === 0) {
          this.thereIsNoPrinter = true
        }
      })
    },
    closeAddPopup() {
      this.addPopupVisible = false
    },
    save() {
      // #region Validation
      this.addLabel.name = this.addfordata.name
      this.addLabel.format = this.addfordata.format
      this.addLabel.width = this.addfordata.width
      this.addLabel.height = this.addfordata.height
      this.addLabel.vendorId = this.selectedPrinterVendor.id
      // #endregion
      printerserverapi.addLabel(this.addLabel).then(result => {
        if (result.data.body === null) {
          Notify.error('Label could not create')
        } else {
          Notify.success('Label created')
          this.addPopupVisible = false
          this.loadLabels(this.selectedPrinterVendor)
          this.clearAddData()
        }
      })
    },
    clearAddData() {
      this.addfordata = {
        name: '',
        height: 0,
        width: 0,
        format: '',
      }
    },
    editAction(e) {
      if (this.editPopupVisible === true) { this.editPopupVisible = false }
      setTimeout(() => {
        this.editPopupVisible = true
      }, 100)
      this.editid = e.row.data.id
      this.editfordata.name = e.row.data.name
      this.editfordata.format = e.row.data.format
      this.editfordata.width = e.row.data.width
      this.editfordata.height = e.row.data.height
    },
    closeEditPopup() {
      this.editPopupVisible = false
    },
    editLocal() {
      if (this.editid === 0) {
        Notify.error('Unexpected label type')
        return
      }
      this.editLabel.name = this.editfordata.name
      this.editLabel.format = this.editfordata.format
      this.editLabel.width = this.editfordata.width
      this.editLabel.height = this.editfordata.height
      //
      printerserverapi.editLabel(this.editid, this.editLabel).then(result => {
        if (result.data.body === null) {
          Notify.error('Printer label could not update')
        } else {
          Notify.success('Printer label updated')
          this.editPopupVisible = false
          this.loadLabels(this.selectedPrinterVendor)
          this.clearEditData()
        }
      })
    },
    clearEditData() {
    },
    deleteAction(e) {
      const { id } = e.row.data
      this.$bvModal
        .msgBoxConfirm(`Are you sure to delete this label? ${e.row.data.name}`, {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value === true) {
            printerserverapi.deleteLabel(id).then(result => {
              if (result.data.body === null) {
                Notify.error('Printer label type could not delete')
              } else {
                Notify.success('Printer label type deleted')
                this.loadLabels(this.selectedPrinterVendor)
              }
            })
          }
        })
    },
    sendTestPrint() {
      if (this.selectedPrinter === null || this.selectedPrinter === undefined) {
        Notify.error('Please select a printer')
      } else if (this.valueOfReview === null) {
        Notify.error('There must be a valid format to print')
      } else {
        this.printByRaw.Vendor = this.selectedPrinter.VendorName
        this.printByRaw.Connection = this.selectedPrinter.Address
        this.printByRaw.RawData = this.valueOfReview
        this.printByRaw.IterateCount = 1
        printeragentapi.printoutbyraw(this.printByRaw).then(result => {
          const response = result.data
          if (response === true) {
            this.printByRaw = new PrintByRaw()
            Notify.success('Print out job sent')
          } else {
            Notify.error('Job failed')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/printermanagement/printermanagement.scss';
</style>
