export class PrintByRaw {
  constructor(
    Vendor = '',
    Connection = '',
    RawData = '',
    IterateCount = 0,
  ) {
    this.Vendor = Vendor
    this.Connection = Connection
    this.IterateCount = IterateCount
    this.RawData = RawData
  }
}
